import React, { useEffect, useState } from "react"
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom"
import userLogoImg from "../images/user_no_logo.png"
import userProfileIcon from "../icons/usericon_1.png"
import userExitIcon from "../icons/exiticon_1.png"
import appIcon from "../icons/app_icon.png"
import { ACCESS_TOKEN_NAME } from "../constants"
import axios from "axios"
import { Endpoints} from "../api/endpoints"
// import userLogoImg from "../images/user_no_logo.png"
import style from "../css/header.module.css"


import { useAuth } from "../hook/useAuth"

const Header = (props) => {


    const [menu, setMenu] = useState(<></>)
    const {user, signout} = useAuth()
    const navigate = useNavigate()

    const location = useLocation()

    useEffect(()=>{

        console.log(user)
        if(user.role === "ROLE_ADMIN" || user.role === "ROLE_MODERATOR"){
            
            setMenu(<>
                <div className={style.menuColumn}>
                    <Link id="linkDashboard" className={(location.pathname === "/dashboard")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/dashboard">Информация</Link>
                    <Link id="linkUsers" className={(location.pathname === "/usersList")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/usersList" >Пользователи</Link>
                </div>
                <div className={style.menuColumn}>
                    <Link id="linkStats" className={(location.pathname === "/statistics")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/statistics" >Статистика</Link>
                    <Link id="linkUpdates" className={(location.pathname === "/appUpdates")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/appUpdates">Обновления</Link>
                </div>
                <div className={style.menuColumn}>
                    <Link id="linkPrices" className={(location.pathname === "/pricesUpdates")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/pricesUpdates" >Цены</Link>
                    <Link id="linkNews" className={(location.pathname === "/news")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/news" onClick={(e) => {e.preventDefault();}}>Новости</Link>
                </div>
                <div className={style.menuColumn}>
                    <Link id="linkClaims" className={(location.pathname === "/claims")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/claims" >Обращения</Link>

                </div>
            </>)

            

        }else if(user.role === "ROLE_KEYMANAGER" || user.role === "ROLE_SHOP"){
            if(location.pathname !== "/profile" &&  location.pathname !== "/myUsersPage")navigate("/profile")
            setMenu(<>
                    <div className={style.menuColumn}>
                        <Link id="linkMyUsersPage" className={(location.pathname === "/myUsersPage")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/myUsersPage" >Пользователи</Link>
                    </div>
                </>)
        }else{
            if(location.pathname !== "/profile")navigate("/profile")
        }



    }, [user, location])


    const getRoleName = (role) => {
        if(role === "ROLE_ADMIN") return "Администратор"
        else if(role === "ROLE_MODERATOR") return "Модератор"
        else if(role === "ROLE_SUPPLIER") return "Поставщик"
        else if(role === "ROLE_KEYMANAGER") return "Менеджер"
        else if(role === "ROLE_SHOP") return "Мебельный салон/Фабрика"
        else if(role === "ROLE_USER") return "Дизайнер/Архитектор"
        else return role;
    }


    const downloadClientSetupFile = async (fileName) => {
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = Endpoints.API.DOWNLOAD_APP + fileName;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
 
    }


    return(
      <>
          <div className={style.menu_container}>
              <div className={style.menu_container_inner}>
                  <div className={style.menu_container_content}>
                    <div className={style.logo_container}>
                        <div className={style.logo} onClick={e=>{navigate("/dashboard")}}></div>
                        <div className={style.logo_text}>ПОРТАЛ АВТОРИЗАЦИИ <br/>КАЛЬКУЛЯТОРА КАМЕННЫХ ИЗДЕЛИЙ</div>
                    </div>


                    <div className={style.menu}>

                        {menu}
                        {/* <div className={style.menuColumn}>
                            <Link id="linkDashboard" className={(location.pathname === "/dashboard")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/dashboard">Информация</Link>
                            <Link id="linkUsers" className={(location.pathname === "/usersList")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/usersList" >Пользователи</Link>
                        </div>
                        <div className={style.menuColumn}>
                            <Link id="linkStats" className={(location.pathname === "/statistics")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/statistics" >Статистика</Link>
                            <Link id="linkUpdates" className={(location.pathname === "/appUpdates")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/appUpdates">Обновления</Link>
                        </div>
                        <div className={style.menuColumn}>
                            <Link id="linkPrices" className={(location.pathname === "/pricesUpdates")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/pricesUpdates" >Цены</Link>
                            <Link id="linkNews" className={(location.pathname === "/news")? style.menu_btn + " " + style.highlight:style.menu_btn} to="/news" onClick={(e) => {e.preventDefault();}}>Новости</Link>
                        </div> */}

                    </div>
                    

                    <div className={style.user_info_container}>
                        <div className={style.user_info_1}>
                            <p className={style.user_name}>{user.loginName}</p>
                            <p className={style.user_role}>{getRoleName(user.role)}</p>
                            
                        </div>
                        <img src={userLogoImg} alt="" />
                        <div className={style.user_info_btn}>
                            <div className={style.profileLink}>
                                <img src={appIcon}  alt="logo" onClick={() => downloadClientSetupFile("KoreanikaV3Setup.exe")}></img>
                                <Link className={style.user_profile} onClick={() => downloadClientSetupFile("KoreanikaV3Setup.exe")}>Скачать приложение</Link>
                            </div>
                            <div className={style.profileLink}>
                                <img src={userProfileIcon}  alt="logo" onClick={() => navigate("/profile")}></img>
                                <Link className={style.user_profile}to="/profile">Редактировать профиль</Link>
                            </div>
                            <div className={style.logoutLink}>
                                <img src={userExitIcon}  alt="logo" onClick={() => {signout(()=>{navigate("/login", {replace: true})})}}></img>
                                <Link className={style.user_logout} onClick={() => {signout(()=>{navigate("/login", {replace: true})})}}>Выйти</Link>
                            </div>

                        </div>

                    </div>
                  </div>
              </div>
          </div>

          <Outlet/>
      </>     
    )

}

export default Header