import react, { useEffect, useState } from "react"
import classes from "./AppSelector.module.css"

const AppSelector = ({apps, onChange}) => {

    // console.log(apps)

    const [appAccessKM, setAppAccessKM] = useState(apps.split(",").indexOf("km") !== -1)
    const [appAccessK, setAppAccessK] = useState(apps.split(",").indexOf("k") !== -1)
    const [appAccessZ, setAppAccessZ] = useState(apps.split(",").indexOf("z") !== -1)
    const [appAccessPM, setAppAccessPM] = useState(apps.split(",").indexOf("pm") !== -1)

    const [value, setValue] = useState(apps)

    useEffect(()=>{
        let userAppAccess = (appAccessK? "++k++":"") + (appAccessKM? "++km++":"") + (appAccessZ? "++z++":"") + (appAccessPM? "++pm++":"")

        userAppAccess = userAppAccess.replaceAll("++++", ",")
        userAppAccess = userAppAccess.replaceAll("++", "")

        onChange(userAppAccess)

    },[appAccessKM, appAccessK, appAccessZ, appAccessPM])


    
    
    return(
        <div className={classes.app_access}>
            {/* <div><input type="checkbox" name="all" checked = {appAccessAll} onChange={e=>{setAppAccessAll(e.target.checked)}}/><label>all</label></div> */}
            <div><input type="checkbox" name="km"  checked = {apps.split(",").indexOf("km") !== -1} onChange={e=>{setAppAccessKM(e.target.checked)}} /><label>KMaster</label></div>
            <div><input type="checkbox" name="k"  checked = {apps.split(",").indexOf("k") !== -1} onChange={e=>{setAppAccessK(e.target.checked)}}/><label>Koreanika</label></div>
            <div><input type="checkbox" name="z"  checked = {apps.split(",").indexOf("z") !== -1} onChange={e=>{setAppAccessZ(e.target.checked)}}/><label>Zetta</label></div>
            <div><input type="checkbox" name="pm"  checked = {apps.split(",").indexOf("pm") !== -1} onChange={e=>{setAppAccessPM(e.target.checked)}}/><label>Промебель</label></div>
        </div>
    )
}

export  default AppSelector