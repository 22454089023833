import {React, useState, useEffect} from "react";
// import { useAuth } from "../hook/useAuth";
import { Endpoints } from "../api/endpoints";
import { ACCESS_TOKEN_NAME } from "../constants";
import userLogoImg from "../images/user_no_logo.png"
import toolImg from "../images/tool_1.png"
import infoIcon from "../icons/infoicon_1.png"
import axios from "axios";

// import "../css/main.css"
import profile from "../css/profile.module.css"

const Profile = () => {

    console.log("stiles")
    console.log(profile)

    const [userInfo, setUserInfo] = useState({        

        login: "",
        email: "",
        fullName: "",
        companyName: "",
        phoneNumber: "",
        address: "",
        certainPlaceAddress: ""})

        const [oldPassword, setOldPassword] = useState("")
        const [newPassword1, setNewPassword1] = useState("")
        const [newPassword2, setNewPassword2] = useState("")

    useEffect(() => {

        getUserInfo()

    },[])

    const getUserInfo = () =>{

        let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);
      
        const config = {
          headers:{
            'Authorization': 'Bearer ' + accessToken,
          }
        };

        axios.get(Endpoints.API.USER_OWN_INFO, config).then((res) => {

            console.log(res.data)
            setUserInfo(res.data)
          
        }).catch((error) =>{

          console.log(error)
        }); 

    }

    const editUserInfo = () =>{

        let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);
      
        const config = {
          headers:{
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json;charset=utf-8'
          }
        };

        let newUserData = {
            // email:userInfo.email,
            fullName: userInfo.fullName,
            companyName: userInfo.companyName,
            phoneNumber: userInfo.phoneNumber,
            address: userInfo.address,
            certainPlaceAddress: userInfo.certainPlaceAddress
        }

        console.log(newUserData)

        axios.put(Endpoints.API.USER_OWN_EDIT_INFO, JSON.stringify(newUserData), config).then((res) => {

            console.log(res.data)


            showMessage("msg_profile", "Сохранено", 3000)

        }).catch((error) =>{

          console.log(error)

          showMessage("msg_profile", "Произошла ошибка", 5000)
        }); 

    }

    const showMessage = (elementId, message, time) =>{

        let container = document.getElementById(elementId)
        let p = container.lastChild;
        container.style.visibility = "visible";
        p.textContent = message
        setTimeout(()=>{
            container.style.visibility = "hidden";
            p.textContent = ""
        }, time)
    }



    const validateNewPassword = (e) => {

        if(e.target.id === "newPasswordField1") setNewPassword1(e.target.value)
        else if(e.target.id === "newPasswordField2") setNewPassword2(e.target.value)

        let field1 = document.getElementById("newPasswordField1")
        let field2 = document.getElementById("newPasswordField2")

        if(field1.value !== field2.value){
            field1.style.color = "red"
            field2.style.color = "red"
        }else{
            field1.style.color = "black"
            field2.style.color = "black"
        }
    }

    const changeOwnPassword = () => {

        let oldPasswordField = document.getElementById("oldPasswordField")

        let newPasswordField1 = document.getElementById("newPasswordField1")
        let newPasswordField2 = document.getElementById("newPasswordField2")

        if(newPasswordField1.value !== newPasswordField2.value){
            newPasswordField1.style.color = "red"
            newPasswordField2.style.color = "red"
            showMessage("msg_password", "Пароли не совпадают", 5000)
            return;
        }else{
            newPasswordField1.style.color = "black"
            newPasswordField2.style.color = "black"
        }


        let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);
      
        const config = {
          headers:{
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json;charset=utf-8'
          }
        };

        let newData = {
            oldPassword: oldPasswordField.value,
            newPassword: newPasswordField1.value
        }

        console.log(newData)

        axios.put(Endpoints.API.USER_CHANGE_OWN_PASSWORD, JSON.stringify(newData), config).then((res) => {

            console.log(res.data)


            showMessage("msg_password", "Пароль успешно изменен", 3000)

        }).catch((error) =>{

          console.log(error)

          showMessage("msg_password", "Произошла ошибка", 5000)
        }); 

    }



    return(
        <>
            <div className={profile.container}>
                <div className={profile.container_mid}>
                    <div className={profile.container_info}>

                        <div className={profile.page_header}>Учетные данные Вашего профиля</div>

                        <div className={profile.data_body}>
                            <div className={profile.user_data_container}>
                                {/* <!--                    <div className="center">-->
                                <!--                        <div className="field_container">-->
                                <!--                            <p>Тип учетной записи:</p>-->
                                <!--                            <select className="role_selector" name="role">-->
                                <!--                                <option value="ROLE_ADMIN">Администратор</option>-->
                                <!--                                <option value="ROLE_MODERATOR">Модератор</option>-->
                                <!--                                <option value="ROLE_SUPPLIER">Поставщик материала</option>-->
                                <!--                                <option value="ROLE_SHOP">Салон</option>-->
                                <!--                                <option value="ROLE_USER">Дизайнер</option>-->
                                <!--                            </select>-->
                                <!--                        </div>-->
                                <!--                    </div>--> */}

                                <div className="center">
                                    <div className={profile.field_container}>
                                        <p>Логин:</p>
                                        <input className="textfield" pattern="[a-zA-Z0-9._]*" title="Символы латиницы, цифры, . и _" type="text" name="login" placeholder="Введите логин" required value={userInfo.login} onChange={e=>{setUserInfo(userInfo => ({...userInfo, ...{login: e.target.value}}))}}/>
                                    </div>
                                </div>
                                <div className="center">
                                    <div className={profile.field_container}>
                                        <p>Email:</p>
                                        <input className="textfield" type="email" name="email" placeholder="Введите email" required value={userInfo.email} onChange={e=>{setUserInfo(userInfo => ({...userInfo, ...{email: e.target.value}}))}}/>
                                    </div>
                                </div>
                                <div className="center">
                                    <div className={profile.field_container}>
                                        <p>ФИО:</p>
                                        <input className="textfield" type="text" name="fullName" placeholder="ФИО" required value={userInfo.fullName} onChange={e=>{setUserInfo(userInfo => ({...userInfo, ...{fullName: e.target.value}}))}}/>
                                    </div>
                                </div>
                                <div className="center">
                                    <div className={profile.field_container}>
                                        <p>Компания:</p>
                                        <input className="textfield" type="text" name="company" placeholder="Компания" required value={userInfo.companyName} onChange={e=>{setUserInfo(userInfo => ({...userInfo, ...{companyName: e.target.value}}))}}/>
                                    </div>
                                </div>

                            </div>
                            <div className={profile.user_data_container}>


                                <div className="center" hidden>
                                    <div className={profile.field_container}>
                                        <p>Адрес компании:</p>
                                        <input className="textfield" type="text" name="address" placeholder="Адрес компании" required value={userInfo.address} onChange={e=>{setUserInfo(userInfo => ({...userInfo, ...{address: e.target.value}}))}}/>
                                    </div>
                                </div>
                                <div className="center">
                                    <div className={profile.field_container}>
                                        <p>Адрес салона продаж:</p>
                                        <input className="textfield" type="text" name="certain_place_address" placeholder="Адрес салона продаж" required value={userInfo.certainPlaceAddress} onChange={e=>{setUserInfo(userInfo => ({...userInfo, ...{certainPlaceAddress: e.target.value}}))}}/>
                                    </div>
                                </div>
                                <div className="center">
                                    <div className={profile.field_container}>
                                        <p>Номер телефона:</p>
                                        <input className="textfield" type="text" name="phone_number" placeholder="Номер телефона" required value={userInfo.phoneNumber} onChange={e=>{setUserInfo(userInfo => ({...userInfo, ...{phoneNumber: e.target.value}}))}}/>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className={profile.user_image}>
                            <img src={userLogoImg} alt="logo"></img>
                            <div>
                                <p>Фото профиля или логотип компании</p>
                                <input type="button" className={profile.btn_select_file} value="Выберите файл"></input>
                            </div>
                        </div>

                        <div className={profile.btn_save_container}>
                            {/* <input className="green-button" type="button" value="Отменить" onClick={() => {getUserInfo()}}/> */}
                            <input  type="button" value="Сохранить" onClick={() => {editUserInfo()}}/>
                        </div>
                        <div  id="msg_profile"className={profile.msg_container} hidden> 
                            <img src={infoIcon} alt="info"></img>
                            <p> Сохранено</p>
                        </div>

                        <div className={profile.toolImage}><img  src={toolImg} alt="tool"></img></div>

                        <div className={profile.page_header}> <p>Изменение пароля</p></div>
                        <div className={profile.password_body}>
                            <div className={profile.user_data_container}>

                                <div className="center">
                                    <div className={profile.field_container}>
                                        <p>Старый пароль:</p>
                                        <input id="oldPasswordField" className="textfield" type="password" name="pw" placeholder="Введите старый пароль" required value={oldPassword} onChange={e => {setOldPassword(e.target.value)}}/>
                                    </div>
                                </div>

                                

                                <div className="center">
                                    <div className={profile.field_container}>
                                        <p>Новый пароль:</p>
                                        <input id="newPasswordField1" className="textfield" type="password" name="pw_new" placeholder="Введите новый пароль" required  value={newPassword1} onChange={e => {validateNewPassword(e)}}/>
                                    </div>
                                </div>
                                <div className="center">
                                    <div className={profile.field_container}>
                                        <p>Повторите новый пароль:</p>
                                        <input id="newPasswordField2" className="textfield" type="password" name="pw_new_repeat" placeholder="Повторите новый пароль" required  value={newPassword2} onChange={e => {validateNewPassword(e)}}/>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className={profile.btn_save_container}>

                            <input type="button" value="Изменить" onClick={() => { changeOwnPassword()}}/>
                        </div>

                        
                        <div id="msg_password" className={profile.msg_container}> 
                            <img src={infoIcon} alt="ifo"></img>
                            <p >Пароль успешно изменен</p>    
                        </div>

                        <div className={profile.footer}>
                            <div className={profile.footer_text}><p>© 2023, ООО «Кореаника»<br/>Все права защищены</p></div> 
                        </div>
                    </div>

                </div>




            </div>
        </>
    )
}

export default Profile