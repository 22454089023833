// import axios from "axios";
import React, { useState } from "react";
// import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "../constants";
import { useAuth } from "../hook/useAuth";
import style from "../css/loginView.module.css"
import { Link, useNavigate } from "react-router-dom";

const LoginView  = ()=>{

    const {signin} = useAuth()
    const navigate = useNavigate()

    // const location = useLocation()
    

    const [loginValue, setLogin] = useState("")
    const [passwordValue, setPassword] = useState("")
    const [wrongPassword, setWrongPassword] = useState(false)

    // constructor(props){
    //     super(props)

    //     this.state = {
    //         login:"",
    //         password:"",
    //         wrongPassword : false
    //     }

    //     this.loginResult = this.loginResult.bind(this)
    // }

    
    const loginFault = ()=>{
        setWrongPassword(true)
        setTimeout(()=>{
            setWrongPassword(false)
        }, 4000);
    }

    
     return (
        <div className={style.auth_container}>
            <div className={style.form_auth_block}>

                <div className={style.logo}></div>

                <p className={style.header_info}>ПОРТАЛ АВТОРИЗАЦИИ<br/>КАЛЬКУЛЯТОРА КАМЕННЫХ ИЗДЕЛИЙ</p>

                <p className={style.form_auth_block_header}>Авторизация</p>

                 <form className={style.login_form} onSubmit={(e) => {e.preventDefault(); signin(loginValue, passwordValue, ()=>{navigate("/dashboard", {replace: true})},loginFault)}}>
                    <div className="center">
                        <div className={style.form_auth_login_block}>

                            <div className={style.auth_icon_block}><i className="fa-solid fa-user"></i></div>
                            <input id="inputLogin" className={style.textfield} type="text" onChange={(e)=>setLogin(e.target.value)} name="auth_login" placeholder="Ваш логин или e-mail" required/>
                        </div>
                    </div>


                    <div className="center">
                        <div className={style.form_auth_password_block}>
                            <div className={style.auth_icon_block}><i className="fa-solid fa-lock"></i></div>
                            <input id="inputPassword" className={style.textfield} type="password" onChange={(e)=>setPassword(e.target.value)} name="auth_password" placeholder="Пароль" required/>
                        </div>
                        
                    </div>
                    <div className={style.fogot_link}><Link to="/forgotPassword"> Я забыл(а) пароль!</Link></div>
                    <input id="btn_submit" className={style.signin_btn} type="submit"  name="form_auth_submit" value="Войти"></input>

                </form>
                            
                <form id="auth_form" className="form_auth_style" method="post"></form>
                <div className="center"><p>или</p></div> 
                
                <input className={style.register_btn} type="button" value="Зарегистрироваться" onClick={()=>{navigate("/register")}}></input>
                
                <div className={style.info_text}>
                    <p>Регистрируясь или авторизуясь <br/>Вы принимаете <a  href=" http://koreanika.ru/politika-konfidentsialnosti.html">условия политики конфиденциальности</a></p>
                </div> 
                <div id="message" className="wrong_password" > {wrongPassword? "Неверный Логин или пароль":""}</div>



            </div>
            <div className={style.footer}>
                <div className={style.footer_img}></div> 
                <div className={style.footer_text}><p>© 2023, ООО «Кореаника»<br/>Все права защищены</p></div> 
            </div>
            
        </div>
    )
    

}

export default LoginView